<template>

    <v-dialog v-model='dialog' height="600">
        <v-alert shaped v-model="alert" dismissible type="success">Editado com Sucesso!</v-alert>
        <v-alert shaped v-model="alert2" dismissible type="success">Salvo com Sucesso!</v-alert>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on" @click="loader()">
                <v-icon v-if="currentItem.id" medium class="mr-2" color="grey">mdi-pencil-outline</v-icon>
                <v-icon v-else large class="mr-2" color="grey">mdi-plus-circle</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-actions class="justify-end">
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-actions>
            <MyCardTitle modulo="Operadora" :card-info="currentItem" />
            <v-card-text>

                <v-row dense>
                    <v-col cols="3">
                        <v-text-field v-model="currentItem.nome" label="Nome Fantasia" outlined></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="currentItem.razao" label="Razão Social" outlined></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select :items="['Empresa', 'Operadora']" v-model="currentItem.empresa"
                            label="Empresa ou Operadora" outlined>
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="currentItem.ans" label="ANS" outlined></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="currentItem.cnes" label="CNES" outlined></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="currentItem.plano" label="Plano" outlined></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="currentItem.cnpj" label="CNPJ" mask="'##.##.###/####-##'"
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="currentItem.cod_hc_slz" label="Código na Operadora"
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="4">

                        <v-autocomplete outlined label="Medicos" v-model="currentItem.medico" return-object
                            :items="medicos" item-value="id" item-text="username" required
                            :rules="[(e) => e !== undefined || 'Obrigatório']"></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="currentItem.enderco" label="Endereço" outlined></v-text-field>
                    </v-col>
                </v-row>


  
                <v-data-table :headers="headers" :items="items" :server-items-length="-1" hide-default-footer
                    class="mb-4">
                    <template v-slot:[`item.operadora`]="{ item }">
                        <v-autocomplete v-model="item.operdora" placeholder="Operadora"></v-autocomplete>


                    </template>
                    <template v-slot:[`item.tabela`]="{ item }">
                        <v-text-field type="number" v-model="item.tabela" placeholder="Tabela"></v-text-field>


                    </template>
                    <template v-slot:[`item.procedimento`]="{ item }">
                        <v-autocomplete v-model="item.procedimento" :items="procedimentos"
                            placeholder="Procedimento" item-value="nome" item-text="nome"></v-autocomplete>


                    </template>
                    <template v-slot:[`item.codigo`]="{ item }">
                        <v-text-field v-model="item.codigo" placeholder="Codigo"></v-text-field>


                    </template>
                    <template v-slot:[`item.preco`]="{ item }">
                        <v-text-field type="number" v-model="item.preco" placeholder="Preço"></v-text-field>

                    </template>
                </v-data-table>
                <v-btn :loading="loading_grava" color="green" @click="tabelaPreco(items)" class="mb-3">
                    <v-icon class="ma-2 ">mdi-content-save-outline</v-icon>{{ btn_gravar }}
                </v-btn>
                <v-row>
                    <v-col cols="8" dense>
                        <v-textarea v-model="currentItem.obs" label="OBS" outlined></v-textarea>
                    </v-col>
                    <v-col cols="4" v-if="currentItem.id">
                        <v-card class="ma-auto">
                            <v-card-title>Logomarca</v-card-title>
                            <v-card-text class="text-center">
                                <v-avatar rounded="" size="100%" max-width="100">
                                    <v-img :loading="loading_avatar" :src="currentItem.logo">
                                    </v-img>
                                </v-avatar>
                            </v-card-text>
                            <v-form ref="form_change_avatar">
                                <v-card-actions class="mx-2">

                                    <v-file-input counter accept="image/png, image/jpeg, image/bmp" show-size
                                        :label="file_label_avatar" @change="onFileSelectedAvatar">
                                    </v-file-input>
                                </v-card-actions>
                                <v-card-actions class="mx-2">
                                    <v-btn @click="changeAvatar('avatar')" outlined block v-if="this.img_avatar"
                                        :loading="loading_avatar">
                                        {{ file_label_avatar }}
                                        <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn :loading="loading_grava" outlined color="green" @click="salvar(currentItem)">
                            <v-icon class="ma-2">mdi-content-save-outline</v-icon>{{ btn_gravar }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-dialog>

</template>
  
<script>
import api from '@/http'

export default {
    name: "Editar",
    components: {
        MyCardTitle: () => import("../../../uteis/card_title.vue")
    },
    props: {
        currentItem: {
            type: Object
        },
    },
    data: () => ({
        dialog: false,
        alert: false,
        alert2: false, // me mostra a tela
        loading_grava: false,
        loading_avatar: false,
        disabled: true,
        medicos: [],
        procedimentos: [],
        btn_gravar: 'gravar',
        file_label_avatar: 'Trocar Logomar',
        img_avatar: null,
        headers: [

            { text: 'Tabela', align: 'start', sortable: false, value: 'tabela' },
            { text: 'Procedimento', value: 'procedimento' },
            { text: 'Código', value: 'codigo' },
            { text: 'Preço', value: 'preco' },
        ],


        items: [
            { tabela: '', procedimento: '', codigo: '', preco: '' },


        ],

    }),
    methods: {
        loader() {

        },
        salvar(currentItem) {
            if (!currentItem.id) {
                console.log(currentItem)
                this.loading = true
                api.post(`operadoras/operadoras/`, currentItem).then(() => {
                    this.alert2 = true




                })

                this.loading = false
            } else {

                api.put(`operadoras/operadoras/${currentItem.id}/`, currentItem).then(() => {
                    this.alert = true




                })
            }

        },
        tabelaPreco(item) {
            console.log(item)
            item[0].operadora=`${this.currentItem.razao}`
            api.post('procedimentos/procedimentos-por-operadora/', item[0]).then(() => {
                console.log('ok')
            })
        },
         getProcedimentos() {
             api.get('procedimentos/procedimentos/').then((response) => {
                 this.procedimentos = response.data
                 console.log(response.data)
             })
         },

        getMedicos() {
            api.get('contas/medicos/').then((response) => {
                this.medicos = response.data
            })
        },

        onFileSelectedAvatar(event) {
            this.img_avatar = event
            this.pessoa.avatar = this.img_avatar != null ? window.URL.createObjectURL(event) : null;
        },
        onFileSelected(event) {
            this.img_avatar = event
            this.pessoa.carimbo = this.img_avatar != null ? window.URL.createObjectURL(event) : null;
        },
        changeAvatar(val) {

            var fd = new FormData()

            if (val == 'avatar') {
                fd.append('avatar', this.img_avatar);
            } else {
                fd.append('carimbo', this.img_avatar);
            }
            api.put(`contas/usuario-avatar/${this.pessoa.id}/`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                if (val == 'avatar') {
                    this.msg_avatar = 'avatar atualizado com sucesso'
                    this.msg_error_avatar = ''
                    this.file_label_avatar = 'Atualizado!'
                } else {
                    this.msg_carimbo = 'carimbo atualizado com sucesso'
                    this.msg_error_avatar = ''
                    this.file_label_carimbo = 'Atualizado!'
                }
            }).catch(() => {
                if (val == 'avatar') {
                    this.msg_error_avatar = 'erro ao atualizar'
                    this.file_label_avatar = 'erro ao atualizar Avatar'
                } else {
                    this.msg_error_carimbo = 'erro ao atualizar'
                    this.file_label_carimbo = 'erro ao atualizar Avatar'
                }
            }).finally(() => {
                this.loading_carimbo = false
                this.loading_avatar = false
                this.msg_avatar = ''
            })

        },

    },
    mounted() {
        this.getMedicos()
        this.getProcedimentos()
    }
}
</script>